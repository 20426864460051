import React from "react";

export default function Header() {
  return (
    <div className="header-style">
    <header className="ui centered">
      <h1 className="ui center">Rick &amp; Morty Fan Page</h1>
    </header>
    </div>
  );
}
